@import '@/app/styles/variables';
@import '@/app/styles/mixins';

.wrapper {
  position: relative;
  flex: 1;

  .inputError {
    border: 1px solid red !important;
  }
}

.input {
  resize: none;
  outline: none;
  font-size: 14px;
}

.error {
  span {
    position: absolute;
    bottom: -2px;
    font-size: 10px !important;
    text-transform: none !important;
    font-weight: 400 !important;
    color: red;
    left: 20px;
  }
}

.icon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translate(0, -50%);
  display: flex;
  align-items: center;
}

.glassWrapper {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  display: flex;
  align-items: center;

  svg {
    margin: 0 !important;
    height: auto !important;
    width: 20px;
    padding: 0 !important;
  }
}
