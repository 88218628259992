.tabsContainer
  display: flex
  gap: 15px

  @media (max-width: 768px)
    gap: 5px

  > .tabs
    height: 50px
    width: 100%
    display: flex
    // background: linear-gradient(0deg, #6B0000 0%, #FF0A13 99.97%)
    justify-content: space-between
    border-radius: 100px

    @media (max-width: 768px)
      height: 35px
      gap: 10px
      margin-bottom: 10px

    > div
      flex-shrink: 0
      width: 1px
      margin: 10px 0px
      background: linear-gradient(135deg, #8C6034 11.69%, #A9824F 22.66%, #F2D893 46.82%, #FFE79F 50.87%, #D7B674 64.67%, #96662E 88.34%)

      @media (max-width: 768px)
        margin: 8px 0px

    > button
      box-sizing: border-box
      width: 100%
      margin: 0
      padding: 10px 40px
      border: none
      background: transparent
      color: white
      text-transform: uppercase
      font-size: 20px !important
      font-weight: 400 !important
      background: #fff
      text-align: center
      border-radius: 50px
      position: relative
      display: block

      // background: #fff
      // color: #000

      &:before
        content: attr(data-content)
        width: 100%
        height: 100%
        font-weight: 600
        font-size: 16px
        line-height: 19px
        display: flex
        align-items: center
        text-align: center
        text-transform: uppercase
        justify-content: center
        background: #000
        -webkit-background-clip: text
        -webkit-text-fill-color: transparent
        -webkit-mask: none
        background-clip: text
        text-fill-color: transparent

      &.active
        background: linear-gradient(0deg, #6B0000 0%, #FF0A13 99.97%)

        color: linear-gradient(173deg, #8c6034 11.69%, #a9824f 22.66%, #f2d893 46.82%, #ffe79f 50.87%, #d7b674 64.67%, #96662e 88.34%)
        &:before
          content: attr(data-content)
          width: 100%
          height: 100%
          font-weight: 600
          font-size: 16px
          line-height: 19px
          display: flex
          align-items: center
          text-align: center
          text-transform: uppercase
          justify-content: center
          background: linear-gradient(173deg, #8c6034 11.69%, #a9824f 22.66%, #f2d893 46.82%, #ffe79f 50.87%, #d7b674 64.67%, #96662e 88.34%)
          -webkit-background-clip: text
          -webkit-text-fill-color: transparent
          -webkit-mask: none
          background-clip: text
          text-fill-color: transparent

        &:after

          content: ''
          position: absolute
          top: -1.52px
          left: -2px
          width: 100%
          height: 100%
          border-radius: 100px
          padding: 1.52px
          background: linear-gradient(173deg, #8c6034 11.69%, #a9824f 22.66%, #f2d893 46.82%, #ffe79f 50.87%, #d7b674 64.67%, #96662e 88.34%)
          -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)
          -webkit-mask-composite: xor
          -webkit-background-clip: border-box
        // background: -webkit-linear-gradient(135deg, #8C6034 11.69%, #A9824F 22.66%, #F2D893 46.82%, #FFE79F 50.87%, #D7B674 64.67%, #96662E 88.34%)
        // -webkit-background-clip: text
        // -webkit-text-fill-color: transparent

      @media (max-width: 768px)
        padding: 7px 8px
        font-size: 10px !important
        font-weight: 400 !important

  > .button
    position: relative
    display: flex
    align-items: center
    justify-content: center
    flex-shrink: 0
    width: 47px
    height: 47px
    margin: 0
    padding: 0
    border: none
    border-radius: 50%
    background: linear-gradient(0deg, #6B0000 0%, #FF0A13 99.97%), linear-gradient(135deg, #8C6034 11.69%, #A9824F 22.66%, #F2D893 46.82%, #FFE79F 50.87%, #D7B674 64.67%, #96662E 88.34%)

    @media (max-width: 768px)
      width: 32px
      height: 32px

      > svg
        width: 24px
        height: 24px

    &::after
      content: ''
      position: absolute
      top: -1.52px
      left: -2px
      width: 100%
      height: 100%
      border-radius: 100px
      padding: 1.52px
      background: linear-gradient(135deg, #8C6034 11.69%, #A9824F 22.66%, #F2D893 46.82%, #FFE79F 50.87%, #D7B674 64.67%, #96662E 88.34%)
      -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)
      -webkit-mask-composite: xor
