@import '@/app/styles/variables';
@import '@/app/styles/mixins';

.wrapper {
}

.logo {
  margin-bottom: 24px;

  svg {
    height: 70px;
  }
}

.bodyText {
  margin-top: 14px;
  margin-bottom: 10px;

  @include xs-block() {
    margin-top: 16px;
    margin-bottom: 3px;
  }
}

.text {
  font-weight: 500;
  font-size: 24px;
  color: #c7c7c7;
  line-height: 28.25px;

  @include sm-block() {
    font-size: 13px;
    font-weight: 400;
    line-height: 18px;
    text-align: center;
  }

  &.email {
    font-weight: 500;
    color: #fff;
    word-break: break-word;
  }
}

.title {
  display: none;

  @include sm-block() {
    display: block;
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 14px;
    text-align: left;
  }

  @include xs-block() {
    text-align: center;
    margin-top: 30px;
    font-size: 20px;
    font-weight: 600;
    line-height: 23.86px;
    margin-bottom: 0;
  }
}

.info {
  max-width: 300px;
  font-size: 14px;
  font-weight: 500;
  margin: auto;
  text-align: center;
  color: $grayLight;

  @include sm-block() {
    margin: 24px 0 0 0;
  }

  @include sm-block() {
    display: none;
  }
}
