@import '@/app/styles/variables';
@import '@/app/styles/mixins';

.cardSlotItem {
  width: 100%;
  max-width: 280px;
  height: 180px;
  background: #fff;
  position: relative;
  cursor: pointer;
  overflow: hidden;
  border-radius: 10px;
  border: 1px solid $GrayDark;
  background: $GrayDark;

  @include sm-block() {
    height: 153px;
  }

  @include xs-block() {
    height: 93px;
    margin: 0;
  }

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  .title {
    position: absolute;
    background: $GreyDark_2;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    width: 100%;
    height: 40px;
    bottom: 0;
    display: flex;
    align-content: center;
    align-items: center;
    padding: 0 20px;

    @include sm-block() {
      font-size: 18px;
      height: 30px;
    }

    @include xs-block() {
      height: 20px;
      font-size: 10px;
    }
  }
}
