@import '../../../Assets/sass/variables'

.radioBlock
  background: white
  border-radius: 100px
  padding: 12px 30px
  display: flex
  align-items: center
  justify-content: space-between
  cursor: pointer

  @media (max-width: 768px)
    padding: 5px 15px

  > p
    font-weight: 500 !important
    font-size: 16px !important
    line-height: 26px !important
    margin: 0 !important
    color: $GreyDark_3

    @media (max-width: 768px)
      font-size: 13px !important
      line-height: 25px !important

.radio
  > .realRadio
    display: none

.customRadio
  display: flex
  align-items: center
  justify-content: center
  border-radius: 50%
  box-sizing: border-box
  width: 20px
  height: 20px
  border: 1px solid $GreyLight
  background: $GreyLight_very
  flex-shrink: 0

  @media (max-width: 768px)
    width: 16px
    height: 16px

  > .radioMark
    position: relative
    transform: scale(0)
    width: 10px
    height: 10px
    transition: all 100ms ease-in-out

    @media (max-width: 768px)
      width: 8px
      height: 8px

    &::before
      content: ''
      position: absolute
      top: 50%
      left: 50%
      transform: translateX(-50%) translateY(-50%)
      border-radius: 50%
      width: 100%
      height: 100%
      background: linear-gradient(0deg, #6B0000 0%, #FF0A13 99.97%)
      box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.5)

.realRadio:checked + .customRadio > .radioMark
  transform: scale(1)
