.wrapper {
  margin: 0 auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  padding-top: 150px;

  @media (max-width: 768px) {
    padding-top: 60px;
  }
}
