@import '@/app/styles/variables';
@import '@/app/styles/mixins';

.wrapper {
  border-radius: 14px;
  padding: 20px;
  background: #fff;
  display: flex;
  flex-direction: column;
  gap: 15px;
  color: $GreyDark_2;
}
