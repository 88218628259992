@import "quiz"
@import 'animate.css'

@import '@/app/styles/variables'
@import '@/app/styles/mixins'
// @import "fonts"

button, a
  cursor: pointer
  text-decoration: none!important

*
  box-sizing: border-box
  font-family: $GilroyFont

img
  max-width: 100%

#root
  min-height: 100%
  display: flex

.flex-container
  display: flex

.video-react .video-react-big-play-button
  top: calc(50% - 0.75em)!important
  left: calc(50% - 1.5em)!important

.slider__button-simple
  position: relative
  width: 100%
  text-decoration: none
  transition: 300ms
  background: none
  border: none
  color: #fff
  &:after
    content: ''
    position: absolute
    top: -1.52px
    left: -2px
    width: 100%
    height: 100%
    padding: 1.52px
    border: none
  &:before
    content: attr(data-content)
    width: 100%
    height: 100%
    font-weight: 600
    font-size: 16px
    line-height: 19px
    display: flex
    align-items: center
    text-align: center
    letter-spacing: 0.24em
    text-transform: uppercase
    justify-content: center
    font-size: 25px
    background-clip: text
    text-fill-color: transparent
  &:hover
    transform: scale(1.2)

.owl-dots
  display: flex
  height: auto
  .owl-dot
    @include sm-block()
      span
        height: 5px !important
        width: 5px !important
    span
      position: relative
      background: none!important
      &:after
        content: ''
        position: absolute
        bottom: -3px
        left: -3px
        width: 11px
        height: 11px
        border-radius: 100px
        padding: 1.52px
        background: linear-gradient(173deg,#8c6034 11.69%,#a9824f 22.66%,#f2d893 46.82%,#ffe79f 50.87%,#d7b674 64.67%,#96662e 88.34%)
        -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)
        -webkit-mask-composite: xor
        -webkit-background-clip: border-box
        background-clip: text
        border: 1px solid #8c6034
      @include sm-block()
        &:after
          bottom: -4px
          left: -4px
          width: 9px
          height: 9px

    &.active
      span
        background: linear-gradient(173deg,#8c6034 11.69%,#a9824f 22.66%,#f2d893 46.82%,#ffe79f 50.87%,#d7b674 64.67%,#96662e 88.34%)!important

.mce-content-body
  color: #fff
  span
    color: inherit!important

html, body
  margin: 0
  padding: 0
  background: $BlackBackground
  width: 100vw
  height: 100%
  // overflow-x: hidden
  color: white
  font-family: $GilroyFont
  font-weight: $GilroyRegular
  scrollbar-width: none
  &::-webkit-scrollbar
    width: 0
    height: 0
  @media screen and (max-width: 1180px)
    // планшет большой альбомная ориентация
    .tablet-hidden
      display: none !important
  @media screen and (max-width: 768px)
    // планшет средний книжная ориентация
    .mobile-hidden
      display: none!important
      .test
        display: none

  a
    color: white
    list-style: none

  .confirmation
    display: flex
    background: #231F20
    border: 1px solid #443E3F
    box-sizing: border-box
    flex-direction: column
    flex-wrap: nowrap
    align-content: center
    justify-content: space-between
    align-items: center
    height: 300px
    width: 500px
    padding: 30px
    text-align: center
    position: fixed
    top: calc(50% - 150px)
    left: calc(50% - 250px)
    z-index: 99
    @media screen and (max-width: 500px)
      width: 100%
      left: 0

    .title
      font-weight: 500
      font-size: 18px
      line-height: 20px
      text-align: center
      text-transform: uppercase

    .message
      margin: 15px 0 25px 0

  .alert
    display: flex
    background: #231F20
    border: 1px solid #443E3F
    box-sizing: border-box
    flex-direction: column
    flex-wrap: nowrap
    align-content: center
    justify-content: space-between
    align-items: center
    height: 270px
    width: 498px
    padding: 30px
    text-align: center
    @media screen and (max-width: 1180px)
      margin-bottom: 70px !important
    @media screen and (max-width: 500px)
      margin: 50% auto!important
      padding: 24px
      width: 100vw
    @media screen and (max-width: 400px)
      padding: 10px
    @media screen and (max-width: 380px)
      padding: 5px

    .typeAlert
      display: flex
      align-items: center

      svg
        width: 25px
        margin: 10px

  a
    color: white
    list-style: none

  .App
    overflow-y: auto
    -ms-overflow-style: none
    scrollbar-width: none
    max-height: 100%
    //background: url('../img/Rectangle 142.png') center center no-repeat
    background-size: cover
    display: flex
    flex-direction: column
    width: 100%

    main
      flex: 1 0 auto
      @media screen and (max-width: 1180px)
        margin-top: 53px
      // overflow: hidden

    &::-webkit-scrollbar
      width: 0
      height: 0

    .fullBlockNavBar
      transform: scale(0)
      &.active
        transform: scale(1)
      &.navBar
        position: fixed
        bottom: 0
        width: 100%
        display: flex
        flex-direction: column
        flex-wrap: nowrap
        align-items: flex-start
        justify-content: center
        background: rgba(35, 31, 32, 0.85)
        border: 1px solid #443E3F
        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25)
        backdrop-filter: blur(20px)
        border-radius: 30px 30px 0 0
        padding: 15px
        .flex-container
          align-content: center
          align-items: center
          width: 100%
          border-bottom: 1px solid $GrayDark
          padding: 20px 0
          text-decoration: none
          font-weight: 500
          font-size: 18px
          line-height: 22px
          @include xs-block()
            font-size: 14px
            padding: 0
            justify-content: flex-start

          img
            width: 30px
            height: 30px
            margin-left: 18px
            margin-right: 20px
            @include xs-block()
              width: 24px
              height: 24px
          //a
          //  &.header-logo
          //    padding: 47px 0
      .close
        position: absolute
        top: 25px
        right: 25px
        z-index: 100

    &:first-child
      // height: calc(100vh - 85px)
      // height: calc(100vh - 1px)
      padding-top: 85px
      @media screen and (max-width: 1180px)
        height: calc(100vh - 60px)
        padding-top: 1px
        width: 100%
      @media screen and (max-width: 500px)
        height: auto
        margin: 0
        padding: 0
    .AppMainBlock
      margin: 18px auto auto auto
      // overflow-x: hidden
      //max-width: 1180px
      display: flex
      gap: 20px
      width: 100%
      flex-direction: row
      flex-wrap: nowrap
      align-content: space-around
      justify-content: space-around
      max-width: 1440px
      height: auto

      @media screen and (max-width: 1440px)
        padding: 0 10px
      main
        flex: 1 0 auto
      &--full
        padding: 0 !important
      @include md-block()
        padding: 0 20px

      @include sm-block()
        padding: 0 15px
      &.fullDisplay
        max-width: 100vw
        margin-top: 5px
        padding: 0

      p
        font-weight: 500
        font-size: 14px
        line-height: 25px
        margin: 5px 0 25px

      .left
        text-align: start

      .center
        text-align: center

      .right
        text-align: end
      .App-mainBlock
        width: 100%

        @media screen and (max-width: 500px)
          // overflow-y: hidden
          // overflow-x: hidden
        &.left, &.fullWidth
          .content
            width: 100%
            height: 100%
            @media screen and (max-width: 992px)
              height: auto
              @media screen and (max-width: 768px)
                height: 100%

          &.noneFull
            width: 66%
            @media screen and (max-width: 1180px)
              width: 100%
        .video-react
          width: 100%
          // margin-top: 50px

      .ad-block
        width: 34%
        @media screen and (max-width: 1024px)
          display: none

.fullWidth
  margin: 0 -31%

.NoteFound
  display: flex
  width: 100%
  height: calc(100% - 85px)
  align-content: center
  justify-content: center
  align-items: center
