@import '@/app/styles/variables';
@import '@/app/styles/mixins';

.wrapper {
  text-align: center;

  @include sm-block() {
    text-align: left;
  }

  &.two {
    .title {
      max-width: 350px;
      margin: 0 auto;
      margin-bottom: 20px;

      @include sm-block() {
        margin-bottom: 16px;
      }
    }

    .text {
      max-width: 405px;
      margin-bottom: 30px;

      @include sm-block() {
        margin-bottom: 16px;
      }
    }
  }
}

.title {
  font-weight: 700;
  font-size: 24px;
  margin-bottom: 20px;

  @include sm-block() {
    margin-bottom: 14px;
    font-size: 16px;
    font-weight: 700;
  }

  span {
    @include gradientText(
      linear-gradient(
        135deg,
        #8c6034 11.69%,
        #a9824f 22.66%,
        #f2d893 46.82%,
        #ffe79f 50.87%,
        #d7b674 64.67%,
        #96662e 88.34%
      )
    );
  }
}

.text {
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 30px;

  max-width: 370px;
  margin: 0 auto;
  margin-bottom: 40px;

  @include sm-block() {
    font-size: 14px;
    font-weight: 400;
  }
}

.button {
  margin-top: 24px;
}
