.quiz {
  flex: 1;

  .title {
    color: #fff;
    text-align: center;
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: uppercase;
    margin: 0;

    @media (max-width: 768px) {
      font-size: 20px;
    }
  }

  .animate__animated {
    -webkit-animation-duration: 1.8s !important;
    animation-duration: 1.8s !important;
  }

  .left {
    .title {
      text-align: left;
    }
  }

  .center {
    .animate__animated {
      margin: 0 auto;
      width: 100%;
    }

    p {
      text-align: center;
    }
  }

  img {
    width: 100%;
  }

  .text {
    font-family: Gilroy;
    font-style: normal;
    font-weight: 500;
    line-height: 25px;
    color: #fff;
    padding: 0;
    margin: 0;
    font-size: 20px;

    @media (max-width: 768px) {
      font-size: 14px;
      line-height: 18.2px;
    }
  }

  .text-wrapper {
    display: flex;
    flex-direction: column;
    gap: 20px;

    @media (max-width: 768px) {
      gap: 14px;
    }
  }
}
