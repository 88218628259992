@import '../../../../../Assets/sass/variables';

.singleBet {
  position: relative;
  padding: 20px 25px;
  background: white;
  border-radius: 15px;
  overflow: hidden;

  @media (max-width: 768px) {
    padding: 10px;
    border-radius: 10px;
  }

  &:not(:last-child) {
    margin-bottom: 15px;

    @media (max-width: 768px) {
      margin-bottom: 5px;
    }
  }
  > .matchName {
    display: flex;
    align-items: center;
    gap: 15px;
    margin-bottom: 16px;

    @media (max-width: 768px) {
      margin-bottom: 5px;
      gap: 5px;
    }

    > span {
      font-weight: 400;
      font-size: 16px;
      line-height: 25px;
      color: $GreyDark_3;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding-right: 25px;

      @media (max-width: 768px) {
        padding-right: 15px;
        font-size: 10px;
        line-height: 10px;
      }
    }
    > svg {
      width: 35px;
      height: 33px;

      @media (max-width: 768px) {
        width: 15px;
        height: 15px;
      }
    }
  }
  > .teamInfo {
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-bottom: 10px;

    @media (max-width: 768px) {
      margin-bottom: 5px;
      gap: 5px;
    }

    > div {
      display: flex;
      align-items: center;
      justify-content: space-between;

      > span {
        font-weight: 700;
        font-size: 24px;
        line-height: 24px;
        color: $GreyDark_3;

        @media (max-width: 768px) {
          font-size: 10px;
          line-height: 10px;
        }
      }
    }
  }
  > .totalInfo {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 5px;

    > p {
      font-weight: 400 !important;
      font-size: 20px !important;
      color: $GreyDark_3;
      margin: 0 !important;

      @media (max-width: 768px) {
        font-size: 10px !important;
        line-height: 10px !important;
      }

      > span {
        font-weight: 500;
      }
    }
    > span {
      font-weight: 700;
      font-size: 20px;
      color: $GreyDark_3;

      @media (max-width: 768px) {
        font-size: 10px;
      }
    }
  }
}
.close {
  box-sizing: border-box;
  z-index: 1;
  position: absolute;
  top: 25px;
  right: 25px;
  padding: 0;
  border: none;
  background: transparent;
  width: 20px;
  height: 20px;

  @media (max-width: 768px) {
    top: 10px;
    right: 10px;
    width: 10px;
    height: 10px;
  }

  > svg {
    width: 20px;
    height: 20px;

    @media (max-width: 768px) {
      width: 10px;
      height: 10px;
    }
  }
  &.fillWhite {
    > svg {
      fill: white;
    }
  }
}
.blockedOverlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(0deg, #6b0000 0%, #ff0a13 99.97%);
  opacity: 0.9;
  display: flex;
  align-items: center;
  justify-content: center;

  > span {
    font-weight: 500;
    font-size: 32px;
    letter-spacing: 0.1em;
    color: white;

    @media (max-width: 768px) {
      font-size: 16px;
    }
  }
}
.inputCounter {
  @media (max-width: 768px) {
    height: 35px;
    border: 1px solid #d9d9d9;

    > input {
      font-size: 13px;
    }
    > button {
      padding: 0;
      width: 35px;
      height: 35px;

      > svg {
        width: 15px;
        height: 15px;
        flex: 0 0 15px;
      }
    }
  }
}
