@import '@/app/styles/variables';
@import '@/app/styles/mixins';

.slotsBlock {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: space-between;
  justify-content: space-between;
  align-items: center;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1rem;
  height: 100%;
  // max-height: 100vh;
  // overflow: auto;
  // @include scrollbars(4px, $GrayDark, inherit);

  @include md-block() {
    padding: 0 5px;
    margin: 0 -10px;
  }

  @include sm-block() {
    grid-template-columns: repeat(3, 1fr);
  }

  @include xs-block() {
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
  }
}
