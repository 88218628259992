@import '../../../../Assets/sass/variables'

.betsSettings
  box-sizing: border-box
  position: absolute
  height: 100%
  width: 100%
  z-index: 5
  display: flex
  flex-direction: column
  box-sizing: border-box
  height: 100%
  padding: 20px 24px
  background: $BlackBackground

  @media (max-width: 768px)
    padding: 10px 15px

  > h2
    text-transform: uppercase
    text-align: start
    font-weight: 500 !important
    font-size: 20px !important
    line-height: 25px !important
    color: white
    margin: 0 0 20px 0 !important

    @media (max-width: 768px)
      font-size: 10px !important
      line-height: 15px !important
      margin: 0 0 10px 0 !important

  > div
    display: flex
    flex-direction: column
    gap: 15px

    @media (max-width: 768px)
      gap: 5px

.autoMax
  padding: 25px 30px
  border-radius: 15px
  background: white

  @media (max-width: 768px)
    padding: 10px 15px

  > div
    display: flex
    align-items: center
    justify-content: space-between
    margin-bottom: 20px

    @media (max-width: 768px)
      margin-bottom: 10px

    > h3
      color: $GreyDark_3
      font-weight: 500
      font-size: 20px
      margin: 0 !important

      @media (max-width: 768px)
        font-size: 13px !important
        line-height: 13px !important

  > p
    font-weight: 400 !important
    font-size: 16px !important
    line-height: 20px !important
    color: $GreyDark_3
    margin: 0 0 16px 0 !important
    text-align: start

    @media (max-width: 768px)
      margin: 0 !important
      font-size: 13px !important
      line-height: 13px !important

.buttonsContainer
  box-sizing: border-box
  width: 100%
  margin-top: auto

  > div
    display: flex
    flex-wrap: nowrap !important
    gap: 16px

    @media (max-width: 768px)
      gap: 10px

    > .button
      display: inline-block !important
      box-sizing: border-box
      width: 50%
      margin: 0

      @media (max-width: 768px)
        height: 40px
