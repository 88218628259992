@import '../../../../Assets/sass/variables'

.betTypeSelect
  box-sizing: border-box
  position: absolute
  height: 100%
  width: 100%
  z-index: 5
  display: flex
  flex-direction: column
  box-sizing: border-box
  height: 100%
  padding: 20px 24px
  background: $BlackBackground

  @media (max-width: 768px)
    padding: 10px 15px

  > div
    display: flex
    flex-direction: column
    gap: 15px

    @media (max-width: 768px)
      gap: 5px

.buttonsContainer
  box-sizing: border-box
  width: 100%
  margin-top: auto

  > div
    display: flex
    flex-wrap: nowrap !important
    gap: 16px

    @media (max-width: 768px)
      gap: 10px

    > .button
      display: inline-block !important
      box-sizing: border-box
      width: 50%
      margin: 0

      @media (max-width: 768px)
        height: 40px
