@import '@/app/styles/variables';
@import '@/app/styles/mixins';

.select_control,
.select_control_radio {
  background: $black;
  border: 1px solid $gray;
  min-height: 38px;
  border-radius: 12px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding-left: 3px;
  font-size: 14px;
  font-weight: 600;
  line-height: 16.7px;
  letter-spacing: 0.02em;
  color: #fff;
  margin-top: -11px;

  @include sm-block() {
    background: $BlackBackground;
  }

  &:hover {
    border-color: $red !important;
  }

  box-shadow: none !important;
  cursor: pointer;

  &::placeholder {
    color: #a4a4a4;
  }
}

.select_control_radio {
  padding: 0 14px;
}

.modalFooter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 13px;
  font-weight: 600;
  line-height: 15.51px;
  letter-spacing: 0.1em;
  height: 48px;
}

.modalBottom {
  padding-bottom: 0 !important;
}

.modal {
  max-height: 288px;
  background-color: #1a1a1a !important;

  .modalList {
    max-height: 205px;
    overflow: auto;
    @include scrollbars(2px, #ec620f, #bebebe69);
  }

  .modalListNonRadio {
    margin-right: -10px;
    padding-right: 4px;
  }
}

.modalListElement {
  height: 41px;
  display: flex;
  align-items: center;
  margin-left: -20px;
  padding-left: 20px;
  color: #a4a4a4;
  position: relative;
}

.modalTitle {
  font-size: 16px;
  font-weight: 700;
  line-height: 19.81px;
  padding-bottom: 0;
  margin-bottom: -7px;
}
