@import '@/app/styles/variables';
@import '@/app/styles/mixins';

.selectUserType {
  position: absolute;
  z-index: 100;
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;

  .contentBlock {
    padding: 40px;
    position: relative;
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    height: 100%;
    z-index: 0;

    @include xs-block() {
      padding: 16px;
    }

    .selectBlock {
      min-height: 116px;
      margin-bottom: 16px;

      &:first-of-type {
        border-bottom: 1px solid #2a2a2a;
      }
    }

    .text {
      font-size: 14px;
      font-weight: 400;
      line-height: 19px;
      text-align: left;
      margin-top: 6px;
      padding-left: 22px;
      margin-bottom: 16px;
    }

    .checkLabel {
      font-size: 15px;
      font-weight: 600;
      line-height: 18.38px;
      border: 0 !important;
      margin-left: 2px;
    }

    .mobileTitle {
      font-size: 16px;
      font-weight: 700;
      line-height: 19.81px;
      margin-bottom: 20px;
      width: 100%;
    }

    .logo {
      width: 100% !important;
      display: flex;
      justify-content: center;
      margin-bottom: 34px;
    }

    img {
      width: 376px;
      height: 310px;
      object-fit: contain;
    }

    .elements {
      display: flex;
      flex-flow: column;
      align-items: center;

      .info {
        font-weight: 600 !important;
      }
    }

    .button {
      max-height: 52px;
      font-weight: 500 !important;
    }
  }

  .title {
    margin-bottom: 40px;
    background-image: linear-gradient(
      135deg,
      #8c6034 11.69%,
      #a9824f 22.66%,
      #f2d893 46.82%,
      #ffe79f 50.87%,
      #d7b674 64.67%,
      #96662e 88.34%
    ) !important;

    font-size: 64px;
    font-weight: 800;
    line-height: 80px;
    letter-spacing: 0.03em;

    color: transparent;
    -webkit-background-clip: text;
    background-clip: text;
  }

  .content {
    width: 972px;
    height: 732px;
    display: flex;
    gap: 60px;

    .contentBlock {
      padding: 40px;
      position: relative;
      display: flex;
      flex-flow: column;
      justify-content: space-between;
      background: #0f0f10;
      border-radius: 16px;
      height: 100%;

      .mobileTitle {
        font-size: 16px;
        font-weight: 700;
        line-height: 19.81px;
      }

      .logo {
        width: 132px !important;
        width: 42px !important;
      }

      img {
        width: 376px;
        height: 310px;
        object-fit: contain;
      }

      .elements {
        display: flex;
        flex-flow: column;
        align-items: center;
      }

      .button {
        max-height: 52px;
        font-weight: 500 !important;
      }

      @include gradientBorder($border-radius: 16px);
    }
  }
}
