@import '../../../../Assets/sass/variables'

.buttonOption
  padding: 0
  margin: 0
  border: none
  background: transparent
  text-align: start
  font-weight: 500
  font-size: 13px
  line-height: 25px
  color: $GreyDark_3

.container
  position: relative

.currentOption
  display: flex
  align-items: center
  justify-content: space-between
  padding: 7px 15px
  background-color: $GreyLight_very
  border-radius: 30px

  > p
    font-weight: 500
    font-size: 13px
    line-height: 25px
    color: $GreyDark_3
    margin: 0 !important

    &.placeholder
      color: $GreyLight_2

  > .rotated > svg
    transform: rotate(180deg) translateX(-16px)

  button
    padding: 0
    margin: 0
    border: none
    background: transparent
    height: 24px

.popup
  box-sizing: border-box
  width: 100%
  padding: 5px 5px 5px 15px
  position: absolute
  background-color: white
  box-shadow: 5px 1px 19px rgba(0, 0, 0, 0.25)
  border-radius: 6px
  z-index: 2
  top: calc(100% + 4px)

  > div
    padding-right: 10px
    display: flex
    flex-direction: column
    gap: 8px
    max-height: 100px
    overflow-y: auto

    &::-webkit-scrollbar
      width: 2px

    &::-webkit-scrollbar-track
      background: transparent

    &::-webkit-scrollbar-thumb
      background: $GreyLight_very
      border-radius: 2px
