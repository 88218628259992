@import '@/app/styles/variables';
@import '@/app/styles/mixins';

.social {
  display: flex;
  align-items: center;
  gap: 20px;

  span,
  a {
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: all 0.3s ease 0s;

    &:hover {
      svg,
      path {
        fill: #ffe79f;
      }
    }
  }
}

.tooltipWrapp {
  background: none !important;
  padding: 0 !important;
  opacity: 1 !important;

  :global {
    top: 220px;
  }
}

.tooltip {
  position: relative;
  top: 55px;

  @media (max-width: 360px) {
    left: -13px;
  }

  @media (max-width: 340px) {
    left: -23px;
  }
}
