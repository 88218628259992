@import '@/app/styles/variables';
@import '@/app/styles/mixins';

.wrapper {
  position: relative;
  border-radius: 12px;
  margin-bottom: 10px;

  &.noMargin {
    margin-bottom: 0;
  }

  &.inputError {
    border: 1px solid red !important;
  }
}

.mini {
  :global {
    .react-datepicker {
      margin-top: 20px;
      width: 100%;
    }

    .react-datepicker__day-name {
      font-size: 16px !important;
      text-transform: capitalize;
    }

    .react-datepicker__day {
      font-size: 14px;
    }

    .react-datepicker__week:not(:last-child) {
      margin-bottom: 10px;
      margin-top: 0px;
    }
  }
}

.miniMobile {
  :global {
    .react-datepicker {
      margin-top: 20px;
      width: 100%;
    }

    .react-datepicker__day-name {
      font-size: 16px;
      text-transform: capitalize;
    }

    .react-datepicker__day {
      font-size: 14px;
    }

    .react-datepicker__week:not(:last-child) {
      margin-bottom: 10px;
      margin-top: 0px;
    }

    .react-datepicker-popper {
      position: fixed !important;
      inset: 0px auto auto 0px;
      transform: none !important;
      height: 100vh;
      display: flex;
      align-items: center;
      justify-content: center;
      max-width: 100vw;
      background-color: rgba(6, 6, 6, 0.8);
      z-index: 995;
    }

    .footer {
      height: 32px;
    }
  }
}

.displayNone {
  :global {
    .react-datepicker {
      margin-top: 20px;
      width: 100%;
    }

    .react-datepicker__day-name {
      font-size: 16px;
      text-transform: capitalize;
    }

    .react-datepicker__day {
      font-size: 14px;
    }

    .react-datepicker__week:not(:last-child) {
      margin-bottom: 10px;
      margin-top: 0px;
    }

    .react-datepicker-popper {
      display: none !important;
    }
  }
}

.isHigh {
  :global {
    .react-datepicker-wrapper,
    .react-datepicker__input-container {
      height: 52px;

      @include xs-block() {
        height: 32px;
      }
    }
  }
}

.error {
  span {
    position: absolute;
    bottom: -10px;
    font-size: 10px !important;
    text-transform: none !important;
    font-weight: 400 !important;
    color: red;
    left: 20px;
  }
}
