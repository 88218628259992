@import '../../../../Assets/sass/variables';

.controlBlock {
  flex-shrink: 0;
  border-radius: 15px 15px 0px 0px;
  position: relative;
  background: $GreyDark_3;
  background-origin: border-box;
  box-shadow: 13px -28px 58px rgba(0, 0, 0, 0.2);
  padding: 20px;

  @media (max-width: 768px) {
    padding: 10px 15px;
  }

  &::after {
    content: '';
    z-index: 0;
    position: absolute;
    top: -1.52px;
    left: -2px;
    right: -2px;
    height: 100%;
    border-radius: 15px 15px 0px 0px;
    padding: 1.52px;
    background: linear-gradient(
      135deg,
      #8c6034 11.69%,
      #a9824f 22.66%,
      #f2d893 46.82%,
      #ffe79f 50.87%,
      #d7b674 64.67%,
      #96662e 88.34%
    );
    -webkit-mask:
      linear-gradient(#fff 0 0) content-box,
      linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
  }
  > .controlHeader {
    position: relative;
    z-index: 1;
    display: flex;
    gap: 20px;
    margin-bottom: 20px;

    > .currency {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 47px;
      height: 47px;
      border-radius: 50%;
      background: linear-gradient(
        135deg,
        #8c6034 11.69%,
        #a9824f 22.66%,
        #f2d893 46.82%,
        #ffe79f 50.87%,
        #d7b674 64.67%,
        #96662e 88.34%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-weight: 700;
      font-size: 32px;

      @media (max-width: 768px) {
        width: 37px;
        height: 37px;
      }

      &::after {
        content: '';
        position: absolute;
        top: -1.52px;
        left: -2px;
        right: -2px;
        height: 100%;
        border-radius: 50%;
        padding: 1.52px;
        background: linear-gradient(
          135deg,
          #8c6034 11.69%,
          #a9824f 22.66%,
          #f2d893 46.82%,
          #ffe79f 50.87%,
          #d7b674 64.67%,
          #96662e 88.34%
        );
        -webkit-mask:
          linear-gradient(#fff 0 0) content-box,
          linear-gradient(#fff 0 0);
        -webkit-mask-composite: xor;
      }
    }
    > .info {
      > h4 {
        font-weight: 700;
        font-size: 16px;
        line-height: 18px;
        text-decoration: underline;
        color: white;
        margin: 0 !important;
        text-align: start;
        margin-bottom: 10px !important;

        @media (max-width: 768px) {
          font-size: 10px;
          line-height: 15px;
        }
      }
      > div {
        display: flex;
        align-items: center;
        gap: 10px;

        @media (max-width: 768px) {
          gap: 5px;
        }

        > span {
          font-size: 16px;
          color: white;
          font-weight: 700;

          @media (max-width: 768px) {
            font-size: 10px;
            line-height: 15px;
          }

          &:first-child {
            font-weight: 400;
          }
          &:last-child {
            text-decoration: line-through;
          }
        }
      }
    }
    > button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 50px;
      height: 50px;
      padding: 0px;
      margin: 0px;
      margin-left: auto;
      border: none;
      border-radius: 50%;
      background: $GreyDark_2;

      @media (max-width: 768px) {
        width: 40px;
        height: 40px;
      }
    }
  }
}
.tabsContainer {
  position: relative;
  z-index: 2;
  margin-bottom: 15px;
  padding-bottom: 10px;
  border-bottom: 1px solid $GrayDark;

  @media (max-width: 768px) {
    margin-bottom: 5px;
    padding-bottom: 4px;
  }

  > .tabs {
    padding-left: 6px;
    padding-bottom: 6px;
    width: 100%;
    display: flex;
    border-bottom: 1px solid $GrayDark;
    gap: 30px;
    margin-bottom: 10px;

    @media (max-width: 768px) {
      gap: 20px;
      margin-bottom: 5px;
    }

    > button {
      padding: 0;
      margin: 0;
      border: none;
      background: transparent;

      > span {
        display: inline-block;
        position: relative;
        color: $GreyLight;
        font-weight: 500;
        font-size: 16px;
        line-height: 25px;

        @media (max-width: 768px) {
          font-size: 10px;
          line-height: 20px;
        }

        &.active {
          color: white;
          font-weight: 700;

          &::after {
            content: '';
            position: absolute;
            left: 0;
            bottom: -6px;
            width: 100%;
            height: 4px;
            border-radius: 1px 1px 0px 0px;
            background: $RedMain;
          }
        }
      }
    }
  }
  > .tabContent {
    position: relative;
    z-index: 2;

    > span {
      width: 100%;
      text-align: center;
      display: inline-block;
      margin-top: 5px;
      color: white;
      font-size: 14px;
      line-height: 15px;

      @media (max-width: 768px) {
        font-size: 10px;
      }
    }
  }
}
.inputCounter {
  @media (max-width: 768px) {
    height: 35px;

    > input {
      font-size: 13px;
    }
    > button {
      padding: 0;
      width: 35px;
      height: 35px;

      > svg {
        width: 15px;
        height: 15px;
      }
    }
  }
}
.select > div:first-child {
  box-sizing: border-box;
  height: 50px;
  background: white;

  @media (max-width: 768px) {
    height: 35px;
  }
}
.total {
  position: relative;
  z-index: 1;

  > div {
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;

    > p {
      font-weight: 700;
      font-size: 16px;
      color: white;
      margin: 0 !important;

      @media (max-width: 768px) {
        font-size: 10px !important;
        line-height: 15px !important;
      }
    }
  }
}
.betButton {
  margin: 10px 0 0 0;
  width: 100% !important;

  @media (max-width: 768px) {
    margin-top: 5px;
    height: 40px;
  }
}
