@import '@/app/styles/variables';
@import '@/app/styles/mixins';

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 80px;
  z-index: 2;
  position: relative;
  width: 100%;
  padding: 0 10px;
  margin: auto 0;

  :global {
    .center {
      margin: 0 auto;
    }
  }

  @media (max-width: 768px) {
    gap: 40px;
  }
}

.content {
  display: flex;
  flex-direction: column;
  gap: 40px;
  width: 100%;
}

.buttonCollect {
  max-width: 272px;
  width: 100%;

  @media (max-width: 768px) {
    width: 100%;
    max-width: 284px;
    margin: 0 auto;
  }
}

.buttons {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: center;

  @include sm-block() {
  }

  > div {
    max-width: 272px;

    @media (max-width: 768px) {
      max-width: 100%;
      flex: 1;
    }
  }
}
