.loader {
  width: 100%;
  height: auto;
  min-height: 220px;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  align-items: center;

  &.absolute {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 10%);
    top: 0;
    z-index: 2;
  }

  &.tableLoader {
    min-height: 40px;
    max-height: 40px;
    width: 40px;
    top: 50%;
    left: 50%;

    .text {
      font-size: 10px;
    }

    svg {
      max-width: 30px;
    }
  }

  svg {
    max-width: 200px;
    transform-origin: center;
    animation: rotate 2s linear infinite;

    circle {
      fill: none;
      stroke: #dc0000;
      stroke-width: 6;
      stroke-dasharray: 1, 200;
      stroke-dashoffset: 0;
      stroke-linecap: round;
      animation: dash 2.5s ease-in-out infinite;
    }
  }
}

.miniLoader {
  min-height: 40px;

  .text {
    font-size: 10px;
  }

  svg {
    max-width: 50px;
  }
}

.tableLoader {
  min-height: 20px;

  .text {
    font-size: 10px;
  }

  svg {
    max-width: 30px;
  }
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    //animation: dash 5s linear forwards
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
    opacity: 1;
  }

  25% {
    stroke-dasharray: 45, 200;
    stroke-dashoffset: -10px;
    opacity: 0.8;
  }

  50% {
    //animation: dash 5s linear forwards
    stroke-dasharray: 90, 200;
    stroke-dashoffset: -35px;
    opacity: 0.6;
  }

  75% {
    stroke-dasharray: 150, 200;
    stroke-dashoffset: -75px;
    opacity: 0.4;
  }

  100% {
    //animation: dash 5s linear forwards
    stroke-dashoffset: -125px;
    opacity: 0.2;
  }
}
