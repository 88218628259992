@import '@/app/styles/variables'

.slider
  position: relative
  display: inline-block
  height: 18px
  width: 50px
  background: $GrayAccent
  border-radius: 50px
  cursor: pointer

  @media (max-width: 768px)
    height: 12px
    width: 36px

  > .realCheckbox
    display: none

  > .sliderMark
    box-sizing: border-box
    width: 26px
    height: 26px
    border-radius: 50%
    background: $GreyLight_very
    border: 1px solid $GreyLight
    position: absolute
    left: 0px
    top: 50%
    transform: translateY(-50%)
    transition: all 200ms ease-in-out

    @media (max-width: 768px)
      width: 18px
      height: 18px

.realCheckbox:checked + .sliderMark
  left: 100%
  transform: translateY(-50%) translateX(-100%)
