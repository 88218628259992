@import '@/app/styles/variables';
@import '@/app/styles/mixins';

.wrapper {
  display: flex;
  gap: 1rem;
}

.label {
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
  width: 100%;

  span {
    @include sm-block() {
      font-size: 18px;
    }

    @include xs-block() {
      font-size: 14px !important;
    }
  }
}

.icon {
  width: 16px;
  height: 16px;
  border-radius: 100%;
  margin-bottom: 1px;
  overflow: hidden;
  object-fit: contain;
}

.input {
  position: absolute;
  opacity: 0;
  cursor: pointer;

  &:checked {
    ~ .check {
      border: 1px solid $RedMain !important;

      &::after {
        content: '';
      }
    }
  }
}

.titleChecked {
  color: #fff;
}

.labelMobileSelector {
  height: 100%;
  display: flex;
  align-items: center;
  gap: 8px !important;
  font-weight: 600;
}

.titleWrapperMobileSelector {
  height: 100%;
  width: 90%;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #2a2a2a;
  justify-content: space-between;
}

.checkMobileSelector {
  border: 1px solid #a4a4a4 !important;
  margin-bottom: 1px;
  width: 12px !important;
  height: 12px !important;

  &::after {
    width: 6px !important;
    height: 6px !important;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 100%;
    background: linear-gradient(0deg, #6b0000 0%, #ff0a13 99.97%) !important;
  }
}

.check {
  width: 19px;
  height: 19px;
  border-radius: 100%;
  border: 1px solid $GrayDark;
  position: relative;
  transition: all 0.3s ease 0s;

  @include sm-block() {
    width: 14px;
    height: 14px;
  }

  &::after {
    width: 9px;
    height: 9px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 100%;
    background: $RedMain;
  }
}
