.wrapper {
  margin: 0 auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  padding-top: 150px;

  @media (max-width: 768px) {
    padding-top: 60px;
  }
}

.content {
  margin: 0;
}

.image {
  position: relative;
  left: 60px;
  flex: 0 0 50%;

  @media (max-width: 768px) {
    left: 0;
  }
}

.button {
  margin: 0 auto;
  width: 100%;
}
