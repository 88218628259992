@import '@/app/styles/variables'
@import '@/app/styles/mixins'

.header
  display: flex
  align-items: center
  justify-content: space-between
  padding: 12px 20px
  background: $GreyDark_3
  display: grid
  grid-template-columns: repeat(3, 1fr)
  justify-items: center

  @media (max-width: 768px)
    padding: 5px 15px

  h2
    color: white
    font-weight: 500 !important
    font-size: 24px !important
    line-height: 25px !important
    margin: 0 !important
    text-transform: uppercase

    @media (max-width: 768px)
      font-size: 16px !important

  button
    padding: 0
    margin: 0
    background: transparent
    width: 46px
    height: 46px
    border: none
    display: flex
    align-items: center
    justify-content: center
    justify-self: start
    &.cart
      justify-self: end


    @media (max-width: 768px)
      box-sizing: border-box
      width: 30px
      height: 30px

      &:first-child > svg
        width: 20px

    > svg
      flex-shrink: 0

      @media (max-width: 768px)
        height: fit-content
        flex-shrink: 1
