@import '@/app/styles/variables';
@import '@/app/styles/mixins';

.checkbox {
  display: inline-flex;
  align-items: center;
  cursor: pointer;

  &.fullWidth {
    width: 100%;
  }

  &.centerPosition {
    .checkbox__textBlock {
      margin: auto;
    }
  }

  &.disabled {
    cursor: default;
  }

  @media (any-hover: hover) {
    &:hover {
      .checkbox__check {
      }

      .checkbox__text {
        // color: $GrayDark;
        // text-decoration: underline;
      }
    }
  }

  &.disabled {
    &:hover {
      .checkbox__check {
        border: 1px solid transparent;
      }

      .checkbox__text {
        color: inherit;
      }
    }
  }
}

.checkbox__input {
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;

  &:checked {
    + .checkbox__textBlock {
      background: red;
      color: red;
    }
  }
}

.checkbox__check {
  position: relative;
  transition: all 0.2s ease-in-out;
  cursor: pointer;

  &.disabled {
    &:after {
      background: #443e3f !important;
    }
    &:before {
      content: none;
    }
  }

  &::after {
    content: '';
    height: 12px;
    width: 12px;
    background: $GrayDark;
    position: absolute;
    top: 0;
    left: -3.5px;
    transform: translate(0, -50%);
  }

  &:before {
    content: '';
    position: absolute;
    -webkit-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    -o-transition: all 0.2s ease;
    transition: all 0.2s ease;
    left: 2px;
    width: 6px;
    height: 3px;
    border-radius: 1px;
    border-left: 1.5px solid white;
    border-bottom: 1.5px solid white;
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    top: 50%;
    transform: rotate(-45deg) translateY(-100%);
    z-index: 2;
  }
}

.checkbox__textBlock {
  display: inline;
  margin-left: 20px;
}

.checkbox__text {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: $GreyLight;
  transition: all 0.2s ease-in-out;
  padding-left: 1.5rem;
  // line-height: 1.2;

  .tooltip-info {
    margin-left: 10px;
    position: relative;
    top: 4px;
  }
}

.highlight {
  color: #d80027 !important;
}

.checkbox__bracket {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: blue;
}

.checkboxWrap {
  position: relative;
  display: flex;
  width: auto;
  min-height: 16px;
}
