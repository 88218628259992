@import '../../../../Assets/sass/variables'

.emptyBetsBasket
  width: 100%
  display: flex
  flex-direction: column

  > div:first-child
    display: flex
    align-items: center
    justify-content: center
    height: 135px
    background: white
    border-radius: 15px
    margin-bottom: 15px
    padding: 0px 67px

    @media (max-width: 768px)
      height: 85px
      margin-bottom: 5px
      border-radius: 10px

    > p
      text-align: center
      font-weight: 400 !important
      font-size: 16px !important
      line-height: 20px !important
      color: $GreyDark_3
      margin: 0 !important

      @media (max-width: 768px)
        font-size: 13px !important
        line-height: 15px !important
