@import '@/app/styles/variables';
@import '@/app/styles/mixins';

.FormBlock {
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  background: url('../../shared/assets/auth/img/background.webp');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  height: 100vh;
  background-position: center;
  background-size: cover;
  text-transform: none !important;

  @media screen and (max-width: 500px) {
    background-size: cover;
    height: 100vh;

    .langChanger {
      position: absolute;
      top: 29px;
      z-index: 1;
      right: 16px;
    }
  }

  .info {
    display: flex;
    flex-flow: row !important;
  }

  .infoText {
    color: #a4a4a4;
    font-size: 14px;
    margin-right: 4px;
    font-weight: 500;
    text-decoration: none !important;
  }

  .action {
    text-decoration: underline;
  }

  .forgotPassword {
    display: flex;
    flex-flow: row !important;
    justify-content: right;
    text-align: center;
    margin-top: 6px;

    span {
      width: 130px;
    }
  }

  .link {
    color: #20a153;
    cursor: pointer;

    font-size: 14px;
    font-weight: 500;
    line-height: 16.98px;
    text-align: center;
  }

  .buttonContainer {
    margin-top: 0px;

    div {
      font-size: 20px;
      font-weight: 500;
      line-height: 24.5px;
      letter-spacing: 0.03em;
    }
  }

  span {
    text-transform: none;
    font-size: 14px;
    font-weight: 500 !important;
    word-wrap: break-word;
    white-space: break-spaces;
    cursor: pointer;
    transition: all 0.3s ease 0s;

    &:hover {
      // color: $GreyLight
    }

    &.info {
      max-width: 299px;
      text-align: center;
      cursor: 'default';

      &:hover {
        color: #fff;
      }
    }
  }

  .LoginForm,
  .RegisterForm {
    background: #060606;
    padding: 60px 40px;
    width: 526px;
    border: 1px solid #443e3f;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: space-around;
    justify-content: space-around;
    align-items: center;
    position: relative;

    .close {
      position: absolute;
      right: 16px;
      top: 16px;
      cursor: pointer;

      svg {
        height: auto;
        padding: 0;
        margin: 0;
      }
    }

    svg {
      margin-bottom: 20px;
    }

    .formGroup {
      width: 100%;
      align-items: center;

      .auth-block-wrap {
        width: 100%;

        margin-bottom: 30px;
      }

      .inputPass {
        position: relative;

        .openPass {
          cursor: pointer;
          position: absolute;
          right: 20px;
          top: calc(50% - 10px);

          svg {
            height: auto;
            padding: 0;
            margin: 0;
          }
        }
      }

      .title {
        font-size: 24px;
        font-weight: 500;
        line-height: 30px;
        letter-spacing: 0.03em;
        margin-bottom: 30px;
        text-align: center;
      }
    }

    div {
      display: flex;
      flex-direction: column;
    }

    a {
      color: #b1b1b1;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      text-decoration: none;
      text-transform: none;

      @media screen and (max-width: 500px) {
        margin-bottom: 30px;
      }

      &.forgot_pass {
        margin-bottom: 30px;
      }
    }

    @media screen and (max-width: 500px) {
      max-width: 100%;
      height: 100% !important;
      padding: 20px 16px;
      margin: auto !important;
      border: none;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      flex-wrap: nowrap;
      align-content: center;

      span {
        font-size: 13px;
      }

      .buttonContainer {
        margin-top: 15px;
      }

      .logo {
        width: 138px;
        height: 44px;
        margin: 0;
      }

      .close {
        position: absolute;
        left: 0;
        top: -28px;
      }

      svg {
        margin: auto;

        @media screen and (max-width: 400px) {
          padding: 0;
        }
      }

      .formGroup {
        width: 100%;
        height: 475px;
        justify-content: center;
        position: relative;
        z-index: 0;

        @media screen and (max-width: 500px) {
          height: 100%;
          justify-content: left;

          .auth-block-wrap {
            margin-bottom: 24px;
          }

          .title {
            width: 190px;
            margin-top: 30px;
            font-size: 20px !important;
            font-weight: 500 !important;
            line-height: 23.86px !important;
            text-align: center;
            margin-bottom: 20px;
          }
        }

        a {
          margin-top: 5px;
          z-index: 1;
        }
      }
    }
  }

  .LoginForm {
    width: 526px;
  }
}
