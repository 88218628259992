@import '@/app/styles/variables';
@import '@/app/styles/mixins';

.coupon {
  width: 100%;
  height: 100vh;
  position: fixed;
  z-index: 10000;
  top: 0;
  display: flex;
  flex-direction: column;
  background: $BlackBackground;
  transform: translateY(-101vh);
}

.couponButtonWidget {
  height: 24px;
  display: flex;
  align-items: center;
  position: relative;

  svg {
    path {
      fill: $grayLight;
    }
  }
}

.couponOpen {
  width: 100%;
  height: 100vh;
  position: fixed;
  z-index: 10000;
  top: 0;
  display: flex;
  flex-direction: column;
  background: $BlackBackground;
}

.content {
  position: relative;
  box-sizing: border-box;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.couponBody {
  padding: 15px 20px 0px 20px;
  background: $BlackBackground;
  height: 100%;
  display: flex;
  flex-direction: column;

  @media (max-width: 768px) {
    padding: 10px 15px 0px 15px;
  }
}

.betsContainer {
  height: 0;
  flex-grow: 1;
  margin-top: 15px;
  padding-bottom: 15px;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: none;
  }

  @media (max-width: 768px) {
    margin-top: 5px;
  }
}

.wrapper {
  height: 100%;
  flex-grow: 1;
}

.circle {
  svg {
    width: 90px;
    height: 90px;
  }

  @include xs-block() {
    svg {
      width: 50px;
      height: 50px;
    }
  }
}

.ticket {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  svg {
    width: 42px;
    transform: scale(2);
  }

  @include xs-block() {
    svg {
      transform: scale(1);
    }
  }
}

.count {
  border-radius: 100%;
  background: #d5070e;
  position: absolute;
  width: 14px;
  height: 14px;
  text-align: center;
  bottom: 2px;
  right: -10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;

  @include xs-block() {
    width: 15px;
    height: 15px;
    font-size: 10px;
    bottom: 3px;
    right: -14px;
  }

  &:after {
    content: '';
    position: absolute;
    top: -1px;
    left: -1px;
    width: 100%;
    height: 100%;
    border-radius: 100px;
    padding: 1px;
    background: linear-gradient(
      173deg,
      #8c6034 11.69%,
      #a9824f 22.66%,
      #f2d893 46.82%,
      #ffe79f 50.87%,
      #d7b674 64.67%,
      #96662e 88.34%
    );
    -webkit-mask:
      linear-gradient(#fff 0 0) content-box,
      linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    -webkit-background-clip: border-box;
    background-clip: text;
  }
}
