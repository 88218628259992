@import '@/app/styles/variables';
@import '@/app/styles/mixins';

.wrapper {
  padding: 0 20px;

  @include md-block() {
    padding: 0 15px;
  }

  @include xs-block() {
    padding: 0;
  }
}
