@import '../../../Assets/sass/variables';

.inputCounter {
  display: flex;
  background: white;
  border-radius: 100px;
  height: 40px;
  border: 1px solid #d9d9d9;

  > input {
    width: 100%;
    border: none;
    border-right: 1px solid #d9d9d9;
    border-left: 1px solid #d9d9d9;
    margin: 0;
    background: white;
    font-weight: 500;
    font-size: 24px;
    line-height: 24px;
    color: $GreyDark_3;
    text-align: center;
    max-width: 100%;
  }

  > button {
    display: flex;
    align-items: center;
    justify-content: center !important;
    margin: 0;
    padding: 0;
    border: none;
    background: transparent;
    flex-shrink: 0;
    padding: 0px 15px;
    align-self: center;
    min-width: 25px;
  }
}
