@import '@/app/styles/variables';
@import '@/app/styles/mixins';

.title {
  font-size: 24px;
  font-weight: 700;
  text-align: center;
  margin-bottom: 20px;

  @include sm-block() {
    text-align: left;

    font-size: 16px;
  }

  &.max {
    max-width: 388px;
    margin: 0 auto;
    margin-bottom: 20px;
  }

  span {
    @include gradientText(
      linear-gradient(
        135deg,
        #8c6034 11.69%,
        #a9824f 22.66%,
        #f2d893 46.82%,
        #ffe79f 50.87%,
        #d7b674 64.67%,
        #96662e 88.34%
      )
    );
  }
}

.reward {
  font-size: 48px;
  font-weight: 600;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 40px;

  @include sm-block() {
    margin-bottom: 14px;
    font-size: 26px;
    margin-top: 14px;
  }

  @include gradientText(
    linear-gradient(
      135deg,
      #8c6034 11.69%,
      #a9824f 22.66%,
      #f2d893 46.82%,
      #ffe79f 50.87%,
      #d7b674 64.67%,
      #96662e 88.34%
    )
  );
}

.rewardMoney {
  width: 100%;
  position: absolute;
  z-index: 1;
  bottom: 0;
  right: 0;
  left: 0;

  > svg {
    position: absolute;
  }

  > img {
    position: relative;
    width: 100%;
    flex-shrink: 0;
    transform: translateY(5px);
  }
}

.rewardLeaves {
  width: 903px;
  height: 518px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  z-index: 1;

  > img {
    width: 903px;
    height: 518px;
  }
}

.none {
  display: none;
}

.button {
  margin: 0 auto;

  &.buttonMax {
    max-width: 400px;
  }
}
