.wrapper {
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;

  @media (max-width: 768px) {
    background-image: none !important;
  }
}

.extraImage {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
}

.hidden.extraImage {
  @media (max-width: 768px) {
    background-image: none !important;
  }
}

.content {
  width: 100%;
  margin: 0 auto;
  height: 100%;
}
