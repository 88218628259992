@import '@/app/styles/variables';
@import '@/app/styles/mixins';

.wrapper {
  display: flex;
  align-items: center;
  // gap: 20px;
  width: 100%;
  margin-left: 10px;

}

.basket {
  display: flex;
  flex-flow: row;
  align-items: center;
  gap: 8px;
  font-weight: 600;
  margin-right: 24px;
  margin-left: auto;
  cursor: pointer;
}

.active {
  border-bottom: 3px solid red;
  cursor: initial;
}

.subMenu {
  position: relative;
  transition: all 0.3s ease 0s;
  padding-right: 11px;

  &::after {
    content: '';
    border: solid rgb(255, 255, 255);
    border-width: 0 1px 1px 0;
    display: inline-block;
    padding: 1px;
    position: absolute;
    transform: rotate(45deg);
    height: 2px;
    width: 2px;
    top: 6px;
    right: 0;
    -webkit-transform: rotate(45deg);
  }

  &.subMenuActive {
    transition: all 0.3s ease 0s;

    &::after {
      content: '';
      transform: rotate(225deg);
    }
  }
}

.element {
  padding: 25px !important;
}

.linkActive {
  border-bottom: 3px solid red;
  cursor: initial;
}

.image {
  flex: 1 0 30px;
  height: 30px;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  outline: 1px solid #8c6034;
  border-radius: 100%;

  &.noBorder {
    outline: none;

    .avatar {
      border: none;
      width: 30px !important;
      height: 30px !important;
    }
  }

  .avatar {
    width: 24px !important;
    height: 24px !important;
    border-radius: 100%;
    overflow: hidden;
    border: 1px solid #8c6034;
  }
}

.league {
  position: absolute;
  width: 22px;
  height: 13px;
  bottom: -7px;
  left: 53%;
  transform: translateX(-50%);
}
