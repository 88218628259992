@import '@/app/styles/variables';
@import '@/app/styles/mixins';

.wrapper {
  overflow: hidden;
}

.header {
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: space-between;
  border-bottom: 1px solid $red;
  padding: 14px 16px;
  cursor: pointer;

  &.active {
    .name {
      color: #c6c6c6;
    }
  }
}

.name {
  font-weight: 700;
  font-size: 16px;
  color: #fff;
}

.arrow {
  display: flex;
  align-items: center;
}

.body {
  padding: 0 16px;

  display: grid;
  grid-template-rows: 0fr;
  transition: grid-template-rows 500ms;

  > div {
    overflow: hidden;
  }
}

.active .body {
  grid-template-rows: 1fr;
}
