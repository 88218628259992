@import '@/app/styles/variables';
@import '@/app/styles/mixins';

.wrapper {
  flex: 1 1 100%;
  width: 100%;
}

.button {
  position: relative;
  border: none;
  cursor: pointer;
  padding: 10px 15px;
  background: none;
  width: 100%;
  font-family: $GilroyFont;
  border-radius: 12px;
  letter-spacing: 3%;
  text-transform: uppercase;
  min-height: 52px;
  font-weight: 600;
  letter-spacing: 0.03rem;

  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;

  @include sm-block() {
    min-height: 38px;
    padding: 7px 15px;
    font-size: 14px;
  }

  &.red {
    position: relative;
    width: 100%;
    text-decoration: none;
    transition: 300ms;
    background: $RedGradientDefault;
    color: $white;

    &:active {
      background: $RedGradientPressed;
    }

    &:hover {
      background: $RedGradientHover;
    }
  }

  &.gold {
    position: relative;
    width: 100%;
    text-decoration: none;
    transition: 300ms;
    background: $GoldGradientDefault;
    color: #030304;
    transition: all 0.3s ease 0s;
    font-size: 20px;

    &:active {
      background: $GoldGradientPressed;
    }

    &:hover {
      background: $GoldGradientHover;
    }

    @include xs-block() {
      height: 40px;
    }
  }

  &.bronze {
    position: relative;
    width: 100%;
    text-decoration: none;
    transition: 300ms;
    background: $BronzeGradientDefault;
    color: #030304;
    transition: all 0.3s ease 0s;
    font-size: 20px;

    &:active {
      background: $BronzeGradientPressed;
    }

    &:hover {
      background: $BronzeGradientHover;
    }

    @include xs-block() {
      height: 40px;
    }
  }

  &.silver {
    position: relative;
    width: 100%;
    text-decoration: none;
    transition: 300ms;
    background: $SilverGradientDefault;
    color: #030304;
    transition: all 0.3s ease 0s;
    font-size: 20px;

    &:active {
      background: $SilverGradientPressed;
    }

    &:hover {
      background: $SilverGradientHover;
    }

    @include xs-block() {
      height: 40px;
    }
  }

  &.platinum {
    position: relative;
    width: 100%;
    text-decoration: none;
    transition: 300ms;
    background: $PlatinumGradientDefault;
    color: #030304;
    transition: all 0.3s ease 0s;
    font-size: 20px;

    &:active {
      background: $PlatinumGradientPressed;
    }

    &:hover {
      background: $PlatinumGradientHover;
    }

    @include xs-block() {
      height: 40px;
    }
  }

  &.success {
    position: relative;
    width: 100%;
    text-decoration: none;
    transition: 300ms;
    background: $GreenGradientDefault;
    color: $white;
    transition: all 0.3s ease 0s;
    font-size: 20px;

    &:active {
      background: $GreenGradientPressed;
    }

    &:hover {
      background: $GreenGradientHover;
    }

    @include xs-block() {
      height: 40px;
    }
  }

  &.inverse {
    position: relative;
    width: 100%;
    text-decoration: none;
    border-radius: 100px;
    transition: 300ms;
    background: linear-gradient(0deg, #6b0000 0%, #ff0a13 99.97%);
    box-shadow: 0 3px 42px rgba(0, 0, 0, 0.5);
    transition: all 0.3s ease 0s;
    font-size: 20px;

    @include xs-block() {
      height: 40px;
    }

    &:after {
      content: '';
      position: absolute;
      top: -1.52px;
      left: -2px;
      width: 100%;
      height: 100%;
      border-radius: 100px;
      padding: 1.52px;
      background: linear-gradient(
        173deg,
        #8c6034 11.69%,
        #a9824f 22.66%,
        #f2d893 46.82%,
        #ffe79f 50.87%,
        #d7b674 64.67%,
        #96662e 88.34%
      );
      -webkit-mask:
        linear-gradient(#fff 0 0) content-box,
        linear-gradient(#fff 0 0);
      -webkit-mask-composite: xor;
      -webkit-background-clip: border-box;
      // background-clip: text;
    }

    background: linear-gradient(180deg, #231f20 2.08%, #2e2a2b 45.37%, #231f20 100%);
    box-shadow: 0 3px 42px rgba(0, 0, 0, 0.5);
    transform: scale(1);

    @media (any-hover: hover) {
      &:hover {
        background: #1e1e1e10;

        &::before {
          -webkit-text-fill-color: #fff;
        }
      }
    }
  }

  &.disabled {
    background: linear-gradient(180deg, #231f20 2.08%, #2e2a2b 45.37%, #231f20 100%);
    box-shadow: 0 3px 42px rgba(0, 0, 0, 0.5);
    transform: scale(1);
    color: #fff;
    cursor: default;

    @media (any-hover: hover) {
      &:hover {
        background: linear-gradient(180deg, #231f20 2.08%, #2e2a2b 45.37%, #231f20 100%);
      }
    }
  }

  &.large {
    @include xs-block() {
      height: 50px;
    }
  }

  &.fullWidth {
    max-width: 100%;
  }

  &.textGold {
    .text {
      background: $GoldGradientText;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  &.small {
    font-size: 14px;
    border-radius: 10px;
    min-height: 34px;
  }

  &.textCapitalize {
    text-transform: capitalize;
  }

  &.textNormalise {
    text-transform: none !important;
  }

  &.ultraSmall {
    min-height: auto;
    padding: 5px 16px;
    font-size: 14px;
    height: auto;
  }
}
