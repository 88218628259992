@import '@/app/styles/variables';
@import '@/app/styles/mixins';

.wrapper {
  position: relative;
  margin-bottom: 1rem;

  :global {
    //TODO убрать отсюда
    .buttonBlackHoverRedBorderGold {
      position: relative;
      width: 51px;
      height: 51px;
      text-decoration: none;
      border-radius: 100px;
      transition: 300ms;
      background: linear-gradient(180deg, #231f20 2.08%, #2e2a2b 45.37%, #231f20 100%);
      box-shadow: 0 3px 42px rgba(0, 0, 0, 0.5);
      transition: all 0.3s ease 0s;

      &.transparent {
        background: transparent;
      }

      &:after {
        content: '';
        position: absolute;
        top: -1.52px;
        left: -2px;
        width: 100%;
        left: 0;
        height: 100%;
        border-radius: 100px;
        padding: 1.52px;
        background: linear-gradient(
          173deg,
          #8c6034 11.69%,
          #a9824f 22.66%,
          #f2d893 46.82%,
          #ffe79f 50.87%,
          #d7b674 64.67%,
          #96662e 88.34%
        );
        -webkit-mask:
          linear-gradient(#fff 0 0) content-box,
          linear-gradient(#fff 0 0);
        -webkit-mask-composite: xor;
        -webkit-background-clip: border-box;

        // background-clip: text
      }

      &:before {
        content: attr(data-content);
        width: 100%;
        height: 100%;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        left: 3px;
        display: flex;
        align-items: center;
        text-align: center;
        letter-spacing: 0.24em;
        text-transform: uppercase;
        justify-content: center;
        background: linear-gradient(
          173deg,
          #8c6034 11.69%,
          #a9824f 22.66%,
          #f2d893 46.82%,
          #ffe79f 50.87%,
          #d7b674 64.67%,
          #96662e 88.34%
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        -webkit-mask: none;
        background-clip: text;
        text-fill-color: transparent;
      }

      &:hover {
        background: linear-gradient(0deg, #6b0000 0%, #ff0a13 99.97%);
        box-shadow: 0 3px 42px rgba(0, 0, 0, 0.5);
        transform: scale(1);
      }

      &.up:before {
        letter-spacing: 0;
        line-height: 0;
        height: 90%;
        margin: 4px 0;
      }
    }
    .owl-dots {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: 20px;
      display: flex;
      align-items: center;
      gap: 6px;
      max-width: 47px;
      width: 100%;
      justify-content: space-between;

      span {
        margin: 0 !important;
      }

      @include sm-block() {
        bottom: 15px;
      }

      @include xs-block() {
        bottom: 20px !important;
      }
    }

    .owl-nav {
      margin-top: 0;
    }

    button {
      width: 70px;
      height: 70px;
      background: rgba(35, 31, 32, 0.6);

      &::before {
        font-size: 40px;
        position: absolute;
        top: 50%;
        transform: translateY(-52%);
        background: #fff;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        -webkit-mask: none;
        background-clip: text;
        text-fill-color: transparent;
        font-weight: 300;
      }
    }

    .owl-nav {
      position: absolute;
      top: 50%;
      width: 100%;
      transform: translateY(-50%);

      div {
        background: none !important;
        width: 70px;
        height: 70px;
        padding: 0 !important;
        position: absolute;
      }
    }

    .owl-prev {
      transform: translateY(-50%);
      left: 95px;
    }

    .owl-next {
      transform: translateY(-50%);
      right: 95px;
    }
  }
}

.item {
  position: relative;
  height: 30vh;
  display: block;

  @include xs-block() {
    height: 165px;
  }

  .image {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  @include sm-block() {
    .image {
      height: 396px;
      object-fit: cover;
    }

    .foreground {
      top: 96%;
      bottom: auto;
    }
  }

  @include xs-block() {
    .image {
      height: 165px;
      object-fit: cover;
    }

    .foreground {
      top: 85%;
      bottom: auto;
    }
  }

  .foreground {
    position: absolute;
    bottom: -60%;
  }
}
