@import "../../variables"

.categoryBlock
  display: flex
  flex-direction: row
  flex-wrap: nowrap
  align-content: center
  justify-content: space-between
  align-items: center

.GameBlock
  // background: $RedMain url("../../../img/Footer/img_3.webp") no-repeat
  // padding-top: 300px

  .SlotsBlock
    display: flex
    flex-direction: row
    flex-wrap: wrap
    align-content: space-between
    justify-content: space-between
    align-items: center
    display: grid
    grid-template-columns: repeat(4, 1fr)
    gap: 1rem
    .CardSlotItem
      width: 100%
      max-width: 280px
      height: 180px
      border-radius: 0 15px
      background: #fff
      margin: 10px 0
      position: relative

      .title
        position: absolute
        background: #231F20
        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25)
        width: 100%
        height: 40px
        bottom: 0
        border-bottom-left-radius: 15px
        display: flex
        align-content: center
        justify-content: center
        align-items: center

// .AdBlock
//   padding-top: 200px
//   background: #231F20 url('../../../img/Vector 1.webp') no-repeat
