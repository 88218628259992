@import '@/app/styles/variables';
@import '@/app/styles/mixins';

.wrapper {
  display: flex;
  gap: 12px;
  justify-content: space-between;
  min-height: 54px;
  padding: 0 12px;

  border-radius: 12px;
  position: relative;
  min-width: 84px;
  display: flex;
  align-items: center;
  cursor: pointer;

  font-weight: 600;
}

.world {
  display: flex;
  gap: 6px;
  align-items: center;
}

.subMenu {
  transition: all 0.3s ease 0s;
  position: absolute;
  visibility: hidden;
  opacity: 0;
  bottom: -14px;
  z-index: -1;
  display: flex;
  flex-direction: column;
  left: 0;
  padding: 14px 12px;
  background: $black;
  min-width: 100%;
  border-radius: 12px;
  border: 1px solid $gray;

  gap: 12px;
  transform: translate(0, 90%);

  &.active {
    z-index: 1;
    visibility: visible;
    opacity: 1;
    transform: translate(0, 100%);
  }
}

.icon {
  position: relative;
  z-index: -1;
  display: flex;
  align-items: center;
}

.element {
  cursor: pointer;
  line-height: 19px;
  border-bottom: 1px solid $gray;
  padding-bottom: 12px;

  &:last-child {
    border-bottom: none;
    padding-bottom: 0;
  }

  &.disabled {
    cursor: default;
  }
}

.wrapperMobile {
  .subMenu {
    left: auto;
    right: 10px;
    min-width: auto;
  }
}

.blockMobile {
  padding: 5px 6px;
  font-weight: 500;
  font-size: 12px;
  background: $GoldGradientDefault;
  color: #030304;
  border-radius: 100px;
  width: 36px;
  display: flex;
  align-items: center;
  justify-content: center;

  &.subMenuActive {
    background: $GoldGradientPressed;
  }
}
