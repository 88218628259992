@import '@/app/styles/variables';
@import '@/app/styles/mixins';

.wrapper {
  text-align: center;
}

.image {
  width: 100%;
  margin: 0 auto;
  max-width: 500px;
}

.message {
  font-size: 30px;

  margin-top: 50px;

  @include md-block() {
    font-size: 16px;
    margin-top: 20px;
  }
}
