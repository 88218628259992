
$black: #111112
$RedMain: #DC0000
$RedMain_filter: #DC000033
$red: #FB0808
$Red_black_gradient_1: linear-gradient(90deg, #A30307 0%, #FF0A13 63.65%)
$Red_black_gradient_2: linear-gradient(180deg, #FF0A13 0%, #A30307 63.65%)
$gold: linear-gradient(180deg, #DE9F17 -0.11%, #CF9A15 31.91%, #FFF68A 67.98%, #FFCF45 99.99%)
$gold_line: linear-gradient(134deg, #A77327 4.48%, #EAD07A 27.16%, #F8E7B8 48.17%, #EED57A 63.85%, #C79F4C 80.13%)
$gold_button: linear-gradient(90deg, #AE7111 0.03%, #FFFF8C 32%, #FFE45B 68.03%, #FFEF7C 100%)

$BlackBackground: #111112
$GrayDark: #443E3F
$GreyDark_2: #343031
$GreyDark_3: #282425
$GreyLight: #c4c4c41a
$GreyLight_2: #29252680
$GreyLight_very: #E8E5E5
$GrayAccent: #383435

$GilroyFont: 'Gilroy', sans-serif
$GilroyThin: 100
$GilroyLight: 300
$GilroyRegular: 400
$GilroyMedium: 500
$GilroyBold: 700
$GilroyBlack: 900

//sizes
$break_lg: 1366px
$break_md: 1180px
$break_sm: 768px
$break_xs: 500px
