@import '@/app/styles/variables';
@import '@/app/styles/mixins';

.wrapper {
  height: 100%;

  iframe {
    height: calc(100vh - 98px);

    @include md-block() {
      height: 600px;
      height: calc(100vh - 250px);
    }

    // @include sm-block() {
    //   height: 500px;
    // }

    @include sm-block() {
      height: calc(100vh - 145px);
    }
  }
}
