@import '@/app/styles/variables';
@import '@/app/styles/mixins';

.layout {
  padding: 0 15px;
}

.containerApp {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding-top: 85px;

  @media screen and (max-width: 1180px) {
    height: calc(100vh - 60px);
    padding-top: 0;
  }

  @media screen and (max-width: 500px) {
    height: auto;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
  }

  main {
    flex: 1 0 auto;
  }

  :global {
    .App-footer {
      flex: 0 0 auto;
    }
  }
}

.main {
}

.fullWidth {
  padding: 0 !important;
}

.articleWrapper {
  margin-top: 38px !important;
  gap: 50px !important;

  @include md-block() {
    padding: 0 20px !important;
    gap: 15px !important;
  }

  @include sm-block() {
    flex-direction: column !important;
    margin-top: 15px !important;
    padding: 0 15px !important;
  }

  .noneFull {
    width: auto;
    flex: 0 1 850px;

    @include sm-block() {
      flex: 0 1 482px;
    }

    @include xs-block() {
      flex: 0 1 auto;
    }
  }

  .adBlock {
    overflow: auto;

    @include md-block() {
      display: block;
    }
  }
}

.slotsWrapper {
  margin-top: 36px !important;
}

.fullHeight {
  height: calc(100vh - 160px) !important;
}

.fullBlock {
  max-width: 100vw;
}

.noneFull {
  width: auto;
}

.adBlock {
  flex: 1 0 280px;

  @include md-block() {
    flex: 1 0 231px;
    display: none;
  }

  @include sm-block() {
    flex: auto;
    max-height: none !important;
  }
}

.startLineInfoWrapper {
  @include md-block() {
    background: $BlackBackground;
    // border-top: 1px solid $red;
    border-bottom: 1px solid $red;
  }

  @include xs-block() {
    padding: 0 15px;
  }
}

.startLineInfo {
  z-index: 10;
  position: relative;
  width: 100%;
  max-width: 1180px;
  flex-wrap: wrap;
  justify-content: space-between;
  justify-items: center;
  text-transform: uppercase;
  line-height: 25px;
  grid-template-columns: repeat(3, 1fr);
  overflow: auto;
  background: $BlackBackground;
  font-size: 15px;
  text-transform: none;

  span {
    white-space: noWrap;
  }

  &.spaceBetween {
    @include xs-block() {
      justify-content: space-between;
    }
  }

  &.row {
    display: flex;
    gap: 15px;

    a {
      @include xs-block() {
        max-width: 22px;
        position: sticky;
        left: 0;
        background-color: $GreyDark_3;
      }
    }

    * {
      @include xs-block() {
        font-size: 16px;
      }
    }
  }

  @include md-block() {
    top: 0;
    left: 0;
    height: 70px;
    display: grid;
    align-content: center;
    align-items: center;
    justify-content: space-between;
    font-weight: 500;
    font-size: 24px;
    line-height: 21px;
    padding: 0 20px;
    background: $BlackBackground;

    flex-wrap: nowrap;
    @include scrollbars(1px, $GrayDark, inherit);

    &.positionCenter {
      text-align: center;
      display: grid;
    }

    &.left {
      .titleInfo {
        flex: none;
      }
    }
  }

  // @include sm-block() {
  //   margin-top: 10px;
  // }

  @include xs-block() {
    display: flex;
    padding: 0;

    justify-content: flex-start;
    gap: 1rem;
    font-size: 16px;

    &.positionCenter {
      display: grid;
    }
  }

  a {
    text-decoration: none;
    display: flex;
    align-content: center;
    align-items: center;
  }

  svg {
    // margin: calc(50% - 23px) 30px;
  }

  ul {
    list-style: none;
    display: flex;
    padding: 0;
  }

  @include xs-block() {
    height: 40px;
  }
}

.buttonBlock {
  display: flex;

  button {
    margin: 7px;
    width: auto;
    background: $RedMain;
    color: white;
    border: none;
    position: relative;

    .langSubMenu {
      position: absolute;
      background: #2c2829;
      border-radius: 8px;
      border: 1px solid #443e3f;
      bottom: -10px;
      right: 50%;
      transform: scale(0) translateY(100%);
      z-index: 10;
      transition: 300ms;
      width: 200px;
      display: flex;
      flex-direction: column;
      align-content: center;
      align-items: center;

      &.active {
        transform: scale(1) translateY(100%);
        z-index: 9999;
      }
    }

    .element {
      padding: 35px;
      width: 100%;
      display: flex;
      font-size: 13px;
      color: white;
      font-weight: 700;
      height: calc(100% - 70px);
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: center;
      align-content: center;
      justify-content: center;
      white-space: nowrap;
      text-decoration: none;
      transition: 400ms;
      cursor: pointer;
    }
  }

  &.wallet {
    padding: 12px 18px;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
  }
}

.titleInfo {
  flex: 1 0 auto;
  text-align: center;
  overflow-x: visible;
  line-height: 25px;

  @include xs-block() {
    max-width: 194px;
    white-space: nowrap;
    display: -webkit-box;
    -webkit-line-clamp: 1; // количество строк
    -webkit-box-orient: vertical;
  }

  &.positionLeft {
    text-align: left;
  }

  &.left {
    text-align: left;
  }

  &.right {
    text-align: right;
  }
}

.extraMenuButton {
  border: none;
  width: 37px;
  outline: none;
  background: none;
  cursor: pointer;

  @include xs-block() {
    width: 20px;
    height: 20px;

    img {
      width: 20px;
      max-width: none;
    }
  }
}

.wrapper {
}

.content {
  position: fixed;
  top: 170px;
  padding-top: 60px;
  height: 100%;
  width: 100%;
  flex: 1 0 100%;
  background: $black;
  left: 0;
  z-index: 1000;
  padding: 0 15px;
  max-width: -webkit-fill-available;
  padding-top: 26px;

  @include xs-block() {
    top: 110px;
  }
}
