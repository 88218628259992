@import "@/app/styles/variables"
@import "@/app/styles/mixins"

.wrapper
  position: relative
  padding: 0 61px
  margin-bottom: 1rem
  @include md-block()
    padding: 0
  :global
    .owl-theme
    .owl-nav
      position: absolute
      top: 50%
      width: 100%
      display: block !important
      @include  md-block()
        display: none !important

      div
        background: none !important
        position: absolute
        transform: translateY(-100%)

    .owl-prev
      left: -58px
      transform: translate(-50%, -100%) !important
    .owl-next
      right: -61px
      transform: translate(50%, -100%) !important

.item
  border-radius: 100px
  padding: 15px 0
  margin: 0 auto
  background: $GreyDark_2
  cursor: pointer
  opacity: 0.7
  @include sm-block()
    padding: 10px 0
  @include  xs-block()
    width: 70px
    padding: 0
  &.active
    background: $Red_black_gradient_1
    cursor: default
    opacity: 1
  img
    width: 70% !important
    margin: 0 auto
    height: 40px
    @include  xs-block()
      width: 85% !important
      height: 30px !important
